import React from "react";

import img1 from "../../../../../images/home/1.png";
import img2 from "../../../../../images/home/2.png";
import img3 from "../../../../../images/home/3.png";
import img4 from "../../../../../images/home/4.png";
import img5 from "../../../../../images/home/5.png";

import "./style.css";

const Images = (props) => {
  return (
    <div className="destination-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ul>
              Notre concept
              <li>
                Vous réservez votre créneau par téléphone ou sur place à notre point
                d'accueil.
              </li>
              <li>
                Notre bateau navette vous récupère à l'accueil et vous conduit
                gratuitement à la plateforme flottante
              </li>
              <li>Sur place vous serez équipés, briéfés et prêts à rider !</li>
              <li>
                Après votre session, notre bateau navette vous ramène
                gratuitement au point d'accueil.
              </li>
            </ul>
          </div>
          <div className="col-lg-8">
            <div className="destination-wrap">
              <div className="row">
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img1} alt="" />
                        <p>La localisation</p>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img3} alt="" />
                        <p>La navette Al Capone</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-right">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img2} alt="" />
                        <p>La base flottante</p>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img4} alt="" />
                        <p>La flotte de jet ski</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img5} alt="" />
                        <p>Le point d'accueil</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
