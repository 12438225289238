import React from "react";

import "./style.css";

const Title = (props) => {
  const { img } = props;

  return (
    <section className="page-title" style={{ backgroundImage: `url(${img})` }}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <h2>{props.pageTitle}</h2>
            <ol className="breadcrumb">
              <li>
                <a href="/fr" title="Location Jet Ski Cannes - Accueil">
                  Accueil
                </a>
              </li>
              <li>
                <span>{props.pagesub}</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
