import React from "react";

import "./style.css";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="wpo-footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <h3>Cannes Jet-Ride Evasion</h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/ChristineDbbs/about/?ref=page_internal"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/cannesjetrideevasion/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget-title">
                    <h3>Contact</h3>
                  </div>
                  <p>
                    Area for jet ski trips: Croisette, Palm Beach, Islands from
                    Lérins, Cap d'Antibes, Massif de L'Estérel, Corniche Golden.
                  </p>
                  <p>
                    Reservations are made only by phone or at our reception
                    point.
                  </p>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="contact-ft">
                    <ul>
                      <li>
                        <i className="fi ti-location-pin"></i>
                        Boulevard Eugène Gazagnaire, Port du Mouré Rouge (near
                        the Cannes Youth sailing base), 06400 Cannes
                      </li>
                      <li>
                        <i className="fi ti-mobile"></i>+33.7.72.50.20.45
                      </li>
                      <li>
                        <i className="fi flaticon-email"></i>
                        cannesjetrideevasion@gmail.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Our pages</h3>
                  </div>
                  <div className="row">
                    <div className="col col-lg-12">
                      <ul>
                        <li>
                          <a href="/en" title="Jet ski rental Cannes - Home">
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            href="/en/prices"
                            title="Jet ski rental Cannes - All our rides"
                          >
                            Jet ski rides
                          </a>
                        </li>
                        <li>
                          <a
                            href="/en/activities/boat-rental"
                            title="Jet ski rental Cannes - Boat Rental"
                          >
                            Boat rental
                          </a>
                        </li>
                        <li>
                          <a
                            href="/en/activities/water-games"
                            title="Jet ski rental Cannes - Water Games"
                          >
                            Water games
                          </a>
                        </li>
                        <li>
                          <a
                            href="/en/contact"
                            title="Jet ski rental Cannes - Contact"
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-12">
              <p className="copyright">
                &copy; 2023 Cannes Jet-Ride Evasion. Tous droits réservés.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
