import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ts3 from "../../../../../images/testimonial/img-3.jpg";

import "./style.css";

class Avis extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      speed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const testimonial = [
      {
        Des: "“JI went with my son for a half hour solo ride. Incredible team. I recommend this address without hesitation!”",
        Title: "Djé D.",
        Sub: "August 2021",
      },
      {
        Des: "“Very friendly team and efficient jet ski. I highly recommend ! The scooters are recent and work perfectly.”",
        Title: "Paul B.",
        Sub: "July 2021",
      },
      {
        tsImg: ts3,
        Des: "“We went on a jet ski ride with friends, great memory. The managers are adorable and the jet ski ride superb”",
        Title: "Estelle K.",
        Sub: "July 2021",
      },
    ];
    return (
      <div
        className="testimonial-area section-padding"
        style={{ background: "#333" }}
      >
        <div className="container">
          <div className="col-12">
            <div className="wpo-section-title">
              <h2>Our riders have given their opinion!</h2>
              <span>All that's missing is yours!</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial-slider clearfix">
                <Slider {...settings}>
                  {testimonial.map((tesmnl, tsm) => (
                    <div className="grid" key={tsm}>
                      <div className="ratting">
                        <ul>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="quote">
                        <p>{tesmnl.Des}</p>
                      </div>
                      <div className="client-info">
                        <div className="client-text">
                          <h5>{tesmnl.Title}</h5>
                          <p>{tesmnl.Sub}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Avis;
