import React, { Fragment } from "react";

import Navbar from "../_common/Navbar";
import Footer from "../_common/Footer";
import Scrollbar from "../_common/Scrollbar";

import Title from "./components/Title";
import Details from "./components/Details";

import imageHeader from "../../../images/header/jeux-nautiques.png";

const JeuxNautiques = () => {
  return (
    <Fragment>
      <Navbar />
      <Title
        img={imageHeader}
        pageTitle={"Water Games"}
        pagesub={"Water Games"}
      />
      <Details blLeft={"order-lg-1"} blRight={"order-lg-2"} />
      <Footer footerClass={"wpo-ne-footer-2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default JeuxNautiques;
