import React from "react";

const Description = (props) => {
    const { title, text1, text2 } = props;

    return(
        <div className="Room-details-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="room-description">
                            <div className="room-title">
                                <h2>{title}</h2>
                            </div>
                            <p className="p-wrap">{text1}</p>
                            <p>{text2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Description;