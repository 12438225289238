import React, { Fragment } from "react";

import Navbar from "../_common/Navbar";
import CardPresentation from "../_common/CardPresentation";
import Footer from "../_common/Footer";
import Scrollbar from "../_common/Scrollbar";

import Title from "./components/Title";

import dsn1 from "../../../images/destination/img-7.jpg";
import imageHeader from "../../../images/header/location-bateaux.png";

import "../../../components/shopsingle/style.css";

const LocationBateaux = () => {
  return (
    <Fragment>
      <Navbar />
      <Title
        img={imageHeader}
        pageTitle={"Location Bateaux"}
        pagesub={"Location Bateaux"}
      />
      <CardPresentation
        img={dsn1}
        title="Louez à la journée"
        sub="Avec équipage"
        text={
          <ul>
            <li>Bateau semi-rigide gamme luxe</li>
            <li>Brig Eagle 10 « No Risk No Fun”</li>
            <li>11 mètres</li>
            <li>12 personnes</li>
            <li>Location avec capitaine</li>
            <li>À l’heure, à la journée ou à la demi-journée</li>
            <li>Navette, Guérite, dépôt sur les îles</li>
            <li>Monaco, Saint Tropez etc…</li>
            <li>Tarifs sur demande au 0772502045 ou au 0608065973</li>
          </ul>
        }
      />
      {/* <TubnailTarif
        title="Location de bateau"
        sub="Location de bateaux à la journée"
        slides={[
          {
            DImg: dsn1,
            Title: "Lorem ipsum",
            Date: "Lorem ipsum",
          },
        ]}
      /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default LocationBateaux;
