import React from "react";

import img1 from "../../../../../images/home/1.png";
import img2 from "../../../../../images/home/2.png";
import img3 from "../../../../../images/home/3.png";
import img4 from "../../../../../images/home/4.png";
import img5 from "../../../../../images/home/5.png";

import "./style.css";

const Images = (props) => {
  return (
    <div className="destination-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ul>
              Our concept
              <li>
                You book your slot by phone or on site at our point of reception.
              </li>
              <li>
                Our shuttle boat picks you up at the reception and drives you
                for free at the floating platform
              </li>
              <li>On site you will be equipped, briefed and ready to ride!</li>
              <li>
                After your session, our shuttle boat brings you back free of
                charge at the reception point.
              </li>
            </ul>
          </div>
          <div className="col-lg-8">
            <div className="destination-wrap">
              <div className="row">
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img1} alt="" />
                        <p>The location</p>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img3} alt="" />
                        <p>The Al Capone Shuttle</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-right">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img2} alt="" />
                        <p>The floating base</p>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img4} alt="" />
                        <p>The jet ski fleet</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img5} alt="" />
                        <p>The welcome point</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
