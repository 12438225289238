import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="wpo-footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <h3>Cannes Jet-Ride Evasion</h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/ChristineDbbs/about/?ref=page_internal"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/cannesjetrideevasion/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget-title">
                    <h3>Contact</h3>
                  </div>
                  <p>
                    Secteur des balades en jet ski : Croisette, Palm Beach, Iles
                    de Lérins, Cap d'Antibes, Massif de L'Estérel, Corniche
                    d'Or.
                  </p>
                  <p>
                    Les réservations se font uniquement sur le site ou à notre
                    point d'accueil. Aucune réservation ne sera prise par
                    téléphone.
                  </p>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="contact-ft">
                    <ul>
                      <li>
                        <i className="fi ti-location-pin"></i>
                        Boulevard Eugène Gazagnaire, Port du Mouré Rouge (à côté
                        de la base de voile Cannes Jeunesse), 06400 Cannes
                      </li>
                      <li>
                        <i className="fi ti-mobile"></i>+33.7.72.50.20.45
                      </li>
                      <li>
                        <i className="fi flaticon-email"></i>
                        cannesjetrideevasion@gmail.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Nos pages</h3>
                  </div>
                  <div className="row">
                    <div className="col col-lg-12">
                      <ul>
                        <li>
                          <a href="/fr">Accueil</a>
                        </li>
                        <li>
                          <a href="/fr/tarifs">Sorties en jet ski</a>
                        </li>
                        <li>
                          <a href="/fr/activites/location-bateaux">
                            Location de bateaux
                          </a>
                        </li>
                        <li>
                          <a href="/fr/activites/jeux-nautiques">
                            Jeux nautiques
                          </a>
                        </li>
                        <li>
                          <a href="/fr/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-12">
              <p className="copyright">
                &copy; 2023 Cannes Jet-Ride Evasion. Tous droits réservés.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
