import React from "react";
import "../style.css";

const Sidebar = (props) => {
  return (
    <div className="col col-lg-4 col-12 order-lg-1">
      <div className="service-sidebar">
        <div className="widget category-widget">
          <h3>Jet Ski Reservation</h3>
          <ul>
            <li>
              <a href="/en" title="Jet ski rental Cannes - Home">
                Home
              </a>
            </li>
            <li className="current">
              <a href="/en/prices" title="Jet ski rental Cannes - Prices">
                All our rides
              </a>
            </li>
            <li>
              <a
                href="/en/activities/water-games"
                title="Jet ski rental Cannes - Water Games"
              >
                Water games
              </a>
            </li>
            <li>
              <a
                href="/en/activities/boat-rental"
                title="Jet ski rental Cannes - Boat rental"
              >
                Boat rental
              </a>
            </li>
          </ul>
        </div>
        <div className="widget contact-widget">
          <div>
            <h4>Learn more</h4>
            <h2>+33.7.72.50.20.45</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
