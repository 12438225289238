import React, { createRef, Fragment, useEffect } from "react";

import Navbar from "../../../components/Navbar";
import Footer from "../../../components/footer";
import Scrollbar from "../../../components/scrollbar";

import Title from "./components/Title";
import Details from "./components/Details";
import FaqSection from "./components/Faq";
import Images from "./components/Images";

import imageHeader from "../../../images/header/tarifs.png";

const TarifsPage = () => {
  const ref = createRef();

  useEffect(() => {
    const iframe = document.getElementsByTagName("iframe");

    // if (ref.current) {
    //   ref.current.appendChild(iframe[0]);
    // }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Title
        img={imageHeader}
        pageTitle={"Tarifs & Informations"}
        pagesub={"Tarifs & Informations"}
      />
      <div style={{ marginTop: 20, marginBottom: -20 }} ref={ref}></div>
      <Details
        title="Nos balades en  jet ski"
        text1="Les tarifs sont TTC et valables pour 1 ou 2 personnes par jet ski. Les tarifs comprennent: la randonnée, le carburant, l'équipement, la navette, l'assurance et l'encadrement."
        text2='Nous vous proposons des randonnées accompagnées (pour les sessions d’1h et +), des initiations ou de l&apos;activité libre dans une grande zone délimitée. Vous pouvez être seul ou à deux sur le jet ski. Nous vous proposons également des prestations spéciales (groupes,séminaires, coucher de soleil...). Pour cela, contactez-nous directement par téléphone ou par email (rubrique "contact").'
      />
      <Images />
      <FaqSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default TarifsPage;
