import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";

import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/fr",
    alt: "Location Jet Ski Cannes - Accueil",
  },
  {
    id: 2,
    title: "Tarifs & Informations",
    link: "/fr/tarifs",
    alt: "Location Jet Ski Cannes - Tarifs et infromations",
  },
  {
    id: 3,
    title: "Autres activités",
    submenu: [
      {
        id: 31,
        title: "Location Bateaux",
        link: "/fr/activites/location-bateaux",
        alt: "Location Jet Ski Cannes - Location bateaux",
      },
      {
        id: 32,
        title: "Jeux nautiques",
        link: "/fr/activites/jeux-nautiques",
        alt: "Location Jet Ski Cannes - Jeux nautiques",
      },
    ],
  },
  {
    id: 5,
    title: "Contact",
    link: "/fr/contact",
    alt: "Location Jet Ski Cannes - Contact",
  },
];

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  render() {
    const { isMenuShow } = this.state;

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <ul className="responsivemenu">
            {menus.map((item) => {
              return (
                <li key={item.id}>
                  {item.submenu ? (
                    <p>
                      {item.title}
                      {item.submenu ? (
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <a href={item.link} title={item.alt}>
                      {item.title}
                    </a>
                  )}
                  {item.submenu ? (
                    <Collapse isOpen={true}>
                      <Card>
                        <CardBody>
                          <ul>
                            {item.submenu.map((submenu) => (
                              <li key={submenu.id}>
                                <a
                                  onClick={ClickHandler}
                                  className="active"
                                  href={submenu.link}
                                  title={submenu.alt}
                                >
                                  {submenu.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </>
    );
  }
}
