import React from "react";
import Images from "./components/Images";
import Text from "./components/Text";
import "./style.css";

const Details = (props) => {
  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <Images />
          </div>
          <Text blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default Details;
