import React from "react";
import {
  FaMedal,
  FaSwimmingPool,
  FaStar,
  FaUmbrellaBeach,
  FaRegClock,
  FaShieldAlt,
} from "react-icons/fa";

import "./style.css";

const Services = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const serviceWrap = [
    {
      sIcon: <FaMedal />,
      title: "Notre staff",
      des: "Un moniteur de jet ski diplômé d'État et champion de France pour vous coacher et vous encadrer.",
    },
    {
      sIcon: <FaSwimmingPool />,
      title: "L'esprit chill",
      des: "Une plateforme flottante avec terrasse pour profiter du soleil et un bateau navette au son de l'été.",
    },
    {
      sIcon: <FaStar />,
      title: "Nos machines",
      des: "Des jet ski neufs et performants pour vos locations (SEADOO 2021 et 2022/ GTX 170/ GTX Pro 130).",
    },
    {
      sIcon: <FaUmbrellaBeach />,
      title: "Le cadre",
      des: "Un emplacement idyllique et privilégié pour vos locations de jet ski longeant les îles de Lérins.",
    },
    {
      sIcon: <FaShieldAlt />,
      title: "La sécurité",
      des: "Un système anti-collision intégré pour vous garantir des balades en jet ski les plus sûres possibles.",
      link: "/service-single",
    },
    {
      sIcon: <FaRegClock />,
      title: "Les horaires",
      des: "Tout pour nos clients ! Nous sommes ouverts 7j/7 de 9h à 20h pour vos locations de jet ski.",
    },
  ];

  return (
    <div className="service-area section-padding">
      <div className="container">
        <div className="col-12">
          <div className="wpo-section-title">
            <h2>Ce qui fait notre charme</h2>
            <span>...et notre originalité !</span>
          </div>
        </div>
        <div className="row">
          {serviceWrap.map((service, sev) => (
            <div className="col col-lg-4 col-md-6 custom-grid col-12" key={sev}>
              <div className="service-item">
                <div className="service-icon">{service.sIcon}</div>
                <div className="service-text">
                  <h2>{service.title}</h2>
                  <p>{service.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
