import React from "react";

import MobileMenu from "../MobileMenu";

import Logo from "../../../../images/logo.jpg";
import en from "../../../../images/lang/en.png";

import "./style.css";

const Header = (props) => {
  // const SubmitHandler = (e) => {
  //     e.preventDefault()
  // }

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="middle-header">
      <div className="header-style-3">
        <div className="container-fluid">
          <div className="header-content">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                <div className="logo">
                  <a href="/fr" title="Location Jet Ski Cannes - Accueil">
                    <img src={Logo} alt="Cannes Jet Ride Evasion" />
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-8 d-lg-block d-none">
                <nav>
                  <ul>
                    <li>
                      <a href="/fr" title="Location Jet Ski Cannes - Accueil">
                        Accueil
                      </a>
                    </li>
                    <li>
                      <a
                        href="/fr/tarifs"
                        title="Location Jet Ski Cannes - Tarifs et informations"
                      >
                        Tarifs & informations
                      </a>
                    </li>
                    <li>
                      <a onClick={() => void null} title="" href="/">
                        Autres activités &#9660;
                      </a>
                      <ul>
                        <li>
                          <a
                            href="/fr/activites/location-bateaux"
                            title="Location Jet Ski Cannes - Location bateaux"
                          >
                            Location Bateaux
                          </a>
                        </li>
                        <li>
                          <a
                            href="/fr/activites/jeux-nautiques"
                            title="Location Jet Ski Cannes - Jeux nautiques"
                          >
                            Jeux nautiques
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href="/fr/contact"
                        title="Location Jet Ski Cannes - Contact"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-xl-3 get-q">
                <div className="get-quote">
                  <a
                    href="https://www.instagram.com/cannesjetrideevasion/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="ti ti-instagram"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/ChristineDbbs/about/?ref=page_internal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="ti ti-facebook"></i>
                  </a>
                  <a href="tel:+33772502045">
                    <i className="ti ti-mobile"></i>
                  </a>
                  <a href="/en" title="Switch language to english">
                    <img src={en} alt="English language" />
                  </a>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <MobileMenu />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
