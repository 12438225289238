import React, { createRef, Fragment, useEffect } from "react";

import Navbar from "../_common/Navbar";
import Footer from "../_common/Footer";
import Scrollbar from "../_common/Scrollbar";

import Title from "./components/Title";
import Details from "./components/Details";
import FaqSection from "./components/Faq";
import Images from "./components/Images";

import imageHeader from "../../../images/header/tarifs.png";

const TarifsPage = () => {
  const ref = createRef();

  useEffect(() => {
    const iframe = document.getElementsByTagName("iframe");

    // if (ref.current) {
    //   ref.current.appendChild(iframe[0]);
    // }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Title
        img={imageHeader}
        pageTitle={"Rates & Information"}
        pagesub={"Rates & Information"}
      />
      <div style={{ marginTop: 20, marginBottom: -20 }} ref={ref}></div>
      <Details
        title="Our jet ski rides"
        text1="Rates are inclusive of VAT and valid for 1 or 2 people per jet ski. Rates include: hiking, fuel, equipment, shuttle, insurance and supervision."
        text2='We offer accompanied hikes (for sessions of 1 hour and more), initiations or free activity in a large delimited area. You can be alone or in pairs on the jet ski. We also offer special services (groups, seminars, sunset...). To do this, contact us directly by phone or email ("contact" section).'
      />
      <Images />
      <FaqSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default TarifsPage;
