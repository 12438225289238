import React from "react";

import about from "../../../../../../../images/blog/profile.png";

import "./style.css";

const Text = (props) => {
  return (
    <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
      <div className="wpo-blog-sidebar">
        <div className="widget profile-widget">
          <div className="profile-img">
            <img src={about} alt="" />
            <h2>
              The Warf Sports
              <br />
              Cannes La Croisette
            </h2>
            <p>
              Pontoon at La Môme and Mademoiselle Gray beaches.
              <br />
              Boulevard de la Croisette, 06400 Cannes
              <br />
              <br />
              Information and booking by phone.
              <br />
              <br />
              Phone: 0623969015 or 0625683167
              <br />
              Instagram: lewarfsports
              <br />
              <a
                href="https://www.lewarfsports.com/"
                title="Jet ski rental Cannes - Water Games"
              >
                https://www.lewarfsports.com/
              </a>
            </p>
          </div>
        </div>
        <div className="widget category-widget">
          <h3>Our activities</h3>
          <ul>
            <li>Parachute</li>
            <li>Towed buoys</li>
            <li>Water Skiing / Wakeboarding</li>
            <li>Paddle</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Text;
