import React, { Component } from "react";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

class Slider extends Component {
  render() {
    var settings = {
      dots: true,
      arrows: true,
      speed: 4500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: true,
    };
    return (
      <div className={`hero ${this.props.heroClass}`}>
        <div className="hero-slider">
          <SlickSlider {...settings}>
            <div className="slide">
              <div
                className="slide-inner"
                style={{ backgroundImage: `url(${this.props.heroImg1})` }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col col-12 slide-caption">
                      <div className="slide-title">
                        <h2>
                          Cannes
                          <br />
                          Jet-Ride Evasion
                        </h2>
                      </div>
                      <div className="slide-subtitle">
                        <p>
                          <span>Unique in Cannes!</span>Floating base of jet ski
                          rental, located in the Iles de Lérins-Palm Beach, near
                          the port of Mouré Rouge.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide">
              <div
                className="slide-inner"
                style={{ backgroundImage: `url(${this.props.heroImg2})` }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col col-12 slide-caption">
                      <div className="slide-title">
                        <h2>
                          Location
                          <br />
                          Jet Ski Cannes
                        </h2>
                      </div>
                      <div className="slide-subtitle">
                        <p>
                          Baladez-vous en jet ski au large de Cannes et ses
                          alentours.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SlickSlider>
        </div>
      </div>
    );
  }
}
export default Slider;
