import React from "react";
import Accordion from "react-bootstrap/Accordion";

import { Card, Button } from "react-bootstrap";

import "./style.css";

const Faq = () => {
  return (
    <section className="faq-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <span>Plus d'informations</span>
              <h2>Les réponses à vos questions</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Accordion className="choose-info" defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    PERMIS OR NOT PERMIS ? Telle est la question.
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    La location de jet ski se fait avec ou sans permis bateau.
                    Les personnes sans permis seront obligatoirement supervisées
                    par un moniteur tandis que celles avec permis pourront
                    naviguer en autonomie.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    AGE MINIMUM
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Vous pouvez piloter un jet ski dès vos 16 ans (si vous avez
                    15 ans et 11 mois, revenez dans un mois ;) ). En revanche,
                    les personnes de moins de 16 ans peuvent être passagères,
                    c'est-à-dire sur la place arrière du jet ski, et en présence
                    de leur tuteur légal. Pour les enfants, il n'y a donc pas
                    d'âge minimum, la seule condition étant que l'enfant se
                    sente prêt.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    SYSTEME DE SECURITE ANTI COLLISION
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Pour votre sécurité, toutes nos machines sont équipées d'un
                    système anti collision de localisation appelé OTOTRAK.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    POINTS IMPORTANTS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    Il est impératif de se présenter 20 minutes avant l'horaire
                    de l'activité afin de remplir le contrat sur place et de
                    s'équiper.
                    <br />
                    La pièce d'identité est OBLIGATOIRE.
                    <br />
                    Pour les locations avec permis, une caution par carte
                    bancaire sera effectuée et la présentation du permis mer est
                    OBLIGATOIRE.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    OÙ RÉSERVER ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    Les réservations se font EXCLUSIVEMENT par téléphone ou sur
                    place à notre point d'accueil. Ce dernier est situé à
                    l'adresse suivante :
                    <br />
                    Boulevard Eugène Gazagnaire, Port du Mouré Rouge (à côté de
                    la base de voile Cannes Jeunesse), 06400 Cannes.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    COMMENT VENIR ET OÙ SE GARER ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    En voiture : possibilité de se garer aux alentours, au
                    parking Palm Beach ou au parking du Port du Mouré Rouge.
                    Tous les stationnements sont gratuits dans ce quartier mais
                    s’y prendre à l’avance en raison des nombreux touristes.
                    <br />
                    En bus : prendre la ligne 8 « Palm Impérial » et descendre à
                    l’arrêt « Esprit Violet ».
                    <br />
                    Vélo/trottinette : piste cyclable plate tout le long de la
                    Croisette.
                    <br />
                    Deux roues : stationnement possible directement à côté de
                    l’accueil.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
