import React from "react";

import dsn1 from "../../../../../images/destination/img-1.jpg";
import dsn2 from "../../../../../images/destination/img-2.jpg";
import dsn3 from "../../../../../images/destination/img-3.jpg";
import dsn4 from "../../../../../images/destination/img-4.jpg";

import "./style.css";

const Images = (props) => {
  return (
    <div className="destination-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="destination-text">
              <div className="wpo-section-title">
                <span>Our prices</span>
                <h2>Jet Ski</h2>
              </div>
              <p>
                Book your jet ski ride in the bay of Cannes and its
                surroundings. Unique in Cannes! Floating jet rental base skis,
                located in the Iles de Lérins-Palm Beach sector, facing the Port
                du Mouré Rouge.
              </p>
              <p>
                Book your jet ski ride slot and meet us at our reception point
                in Cannes, Port du Mouré Rouge. Our shuttle boat will take you
                to the platform to be equipped, briefed and ready for your jet
                ski ride.
              </p>
              <p>
                After your session our shuttle will bring you back to the
                reception.
              </p>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="destination-wrap">
              <div className="row">
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn1} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>A little dose of madness: The Fast Ride</h5>
                        </div>
                        <div className="content-right">
                          <h5>90€</h5>
                          <span>30 min</span>
                        </div>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn3} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>
                            Perfect for discovering the area: The Medium Ride
                          </h5>
                        </div>
                        <div className="content-right">
                          <h5>160€</h5>
                          <span>1 Hour</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-right">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn2} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>Need more discovery: The Long Ride</h5>
                        </div>
                        <div className="content-right">
                          <h5>300€</h5>
                          <span>2 Hour</span>
                        </div>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn4} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>A tailor-made session: The Special Ride</h5>
                        </div>
                        <div className="content-right">
                          <h5>0772502045</h5>
                          <span>
                            3+ Hours
                            <br />
                            seminar, group...
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
