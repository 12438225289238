import React from "react";

import dsn1 from "../../../../../images/destination/img-1.jpg";
import dsn2 from "../../../../../images/destination/img-2.jpg";
import dsn3 from "../../../../../images/destination/img-3.jpg";
import dsn4 from "../../../../../images/destination/img-4.jpg";

import "./style.css";

const Images = (props) => {
  return (
    <div className="destination-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="destination-text">
              <div className="wpo-section-title">
                <span>Nos Tarifs</span>
                <h2>Jet Ski</h2>
              </div>
              <p>
                Réservez votre balade en jet ski dans la baie de Cannes et ses
                alentours. Unique à Cannes ! Base flottante de location de jet
                ski, située dans le secteur Iles de Lérins-Palm Beach, face au
                port du Mouré Rouge.
              </p>
              <p>
                Réservez votre créneau de balade en jet ski et retrouvez nous à
                notre point d'accueil à Cannes, Port du Mouré Rouge. Notre
                bateau navette vous conduira à la plateforme pour être équipés,
                briéfés et prêts pour votre balade en jet ski.
              </p>
              <p>
                Après votre session notre navette vous ramènera à l'acceuil.
              </p>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="destination-wrap">
              <div className="row">
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn1} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>Une petite dose de folie: Le Fast Ride</h5>
                        </div>
                        <div className="content-right">
                          <h5>90€</h5>
                          <span>30 min</span>
                        </div>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn3} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>
                            Parfait pour découvrir le coin: Le Medium Ride
                          </h5>
                        </div>
                        <div className="content-right">
                          <h5>160€</h5>
                          <span>1 Heure</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-right">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn2} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>Besoin de plus de découverte: Le Long Ride</h5>
                        </div>
                        <div className="content-right">
                          <h5>300€</h5>
                          <span>2 Heures</span>
                        </div>
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={dsn4} alt="" />
                      </div>
                      <div className="destination-content">
                        <div className="content-left">
                          <h5>Une session sur-mesure: Le Special Ride</h5>
                        </div>
                        <div className="content-right">
                          <h5>0772502045</h5>
                          <span>3 Heures et +<br/>séminaire, groupe...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
