import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const CardPresentation = (props) => {
  const { title, sub, text, link, img } = props;

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="wpo-about-area section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={img} alt="" />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-section-title">
                <h2>{title}</h2>
                <span>{sub}</span>
              </div>
              <p>{text}</p>
              {link && (
                <div className="btns">
                  <Link
                    onClick={ClickHandler}
                    to={link}
                    className="theme-btn-s2"
                  >
                    En savoir +
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPresentation;
