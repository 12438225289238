import React from "react";
import Accordion from "react-bootstrap/Accordion";

import { Card, Button } from "react-bootstrap";

import "./style.css";

const Faq = () => {
  return (
    <section className="faq-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <span>Plus d'informations</span>
              <h2>The answers to your questions</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Accordion className="choose-info" defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    LICENCE OR NOT LICENCE ? That is the question.
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    Jet ski rental is done with or without a boat license.
                    People without a license will be supervised by an instructor
                    while those with a license can navigate independently.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    MINIMUM AGE
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    You can fly a jet ski from the age of 16 (if you have 15
                    years and 11 months, come back in a month ;) ). In contrast,
                    people under the age of 16 can be passengers, that is to say
                    on the back seat of the jet ski, and in the presence of
                    their legal guardian. For children, therefore, there is no
                    minimum age, the only condition being that the child feel
                    ready.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    ANTI-COLLISION SAFETY SYSTEM
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    For your safety, all our machines are equipped with a
                    localization anti-collision system called OTOTRAK.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    IMPORTANT POINTS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    Il est impératif de se présenter 20 minutes avant l'horaire
                    de l'activité afin de remplir le contrat sur place et de
                    s'équiper.
                    <br />
                    La pièce d'identité est MANDATORY.
                    <br />
                    Pour les locations avec permis, une caution par carte
                    bancaire sera effectuée et la présentation du permis mer est
                    MANDATORY.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    WHERE TO BOOK?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    Reservations are made EXCLUSIVELY by phone or on site at our
                    point reception. It is located at the following address:
                    <br />
                    Boulevard Eugène Gazagnaire, Port du Mouré Rouge (next to
                    the Cannes Youth sailing base), 06400 Cannes.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    HOW TO COME AND WHERE TO PARK?{" "}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    By car: possibility to park nearby, at the Palm Beach car
                    park or at the Port du Mouré Rouge car park. All parking is
                    free in this area but plan ahead due to the many tourists.
                    <br />
                    By bus: take line 8 "Palm Impérial" and get off at the
                    "Purple Spirit" stop.
                    <br />
                    Bike/scooter: flat bike path all along the Croisette.
                    <br />
                    Two wheels: parking possible directly next to home.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
