import React from "react";

import MobileMenu from "../MobileMenu";

import Logo from "../../../../images/logo.jpg";
import fr from "../../../../images/lang/fr.png";

import "./style.css";

const Header = (props) => {
  // const SubmitHandler = (e) => {
  //     e.preventDefault()
  // }

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="middle-header">
      <div className="header-style-3">
        <div className="container-fluid">
          <div className="header-content">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                <div className="logo">
                  <a href="/en" title="Jet ski rental Cannes - Home">
                    <img src={Logo} alt="Cannes Jet Ride Evasion" />
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-8 d-lg-block d-none">
                <nav>
                  <ul>
                    <li>
                      <a href="/en" title="Jet ski rental Cannes - Home">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/en/prices" title="Jet ski rental Cannes - Prices">Prices & informations</a>
                    </li>
                    <li>
                      <a onClick={() => void null} title="" href="/">
                        Other activities &#9660;
                      </a>
                      <ul>
                        <li>
                          <a href="/en/activities/boat-rental" title="Jet ski rental Cannes - Boat rental">
                            Boat rental
                          </a>
                        </li>
                        <li>
                          <a href="/en/activities/water-games" title="Jet ski rental Cannes - Water games">
                            Water Games
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/en/contact" title="Jet ski rental Cannes - Contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-xl-3 get-q">
                <div className="get-quote">
                  <a
                    href="https://www.instagram.com/cannesjetrideevasion/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="ti ti-instagram"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/ChristineDbbs/about/?ref=page_internal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="ti ti-facebook"></i>
                  </a>
                  <a href="tel:+33772502045" title="Jet ski rental Cannes - Call us">
                    <i className="ti ti-mobile"></i>
                  </a>
                  <a href="/fr" title="Switch language to english">
                    <img src={fr} alt="English language" />
                  </a>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <MobileMenu />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
