import React from "react";
import "../style.css";

const Sidebar = (props) => {
  return (
    <div className="col col-lg-4 col-12 order-lg-1">
      <div className="service-sidebar">
        <div className="widget category-widget">
          <h3>Réservation de Jet Ski</h3>
          <ul>
            <li>
              <a href="/fr" title="Location Jet Ski Cannes - Accueil">
                Accueil
              </a>
            </li>
            <li className="current">
              <a href="/fr/tarifs" title="Location Jet Ski Cannes - Tarifs">
                Toutes nos balades
              </a>
            </li>
            <li>
              <a
                href="/fr/activites/jeux-nautiques"
                title="Location Jet Ski Cannes - Jeux Nautiques"
              >
                Jeux nautiques
              </a>
            </li>
            <li>
              <a
                href="/fr/activites/location-bateaux"
                title="Location Jet Ski Cannes - Location Bateaux"
              >
                Location de bateau
              </a>
            </li>
            <li>
              <a href="/fr/contact" title="Location Jet Ski Cannes - Contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="widget contact-widget">
          <div>
            <h4>En savoir plus</h4>
            <h2>+33 7 72 50 20 45</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
