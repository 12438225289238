import React, { Fragment } from "react";

import Navbar from "../_common/Navbar";
import Footer from "../_common/Footer";
import Scrollbar from "../_common/Scrollbar";

import Title from "./components/Title";
import Details from "./components/Details";

import imageHeader from "../../../images/header/contact.png";

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar />
      <Title
        img={imageHeader}
        pageTitle={"Nous contacter"}
        pagesub={"Contact"}
      />
      <Details />
      <Footer footerClass={"wpo-ne-footer-2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
