import React, { createRef, Fragment, useEffect } from "react";

import Navbar from "../_common/Navbar";
import Footer from "../_common/Footer";
import Scrollbar from "../_common/Scrollbar";

import Slider from "./components/Slider";
import Services from "./components/Services";
import Avis from "./components/Avis";
import Images from "./components/Images";

import hero1 from "../../../images/slider/slide-1.jpg";
import hero2 from "../../../images/slider/slide-2.jpg";
import hero3 from "../../../images/slider/slide-3.jpg";

const HomePage = () => {
  const ref = createRef();

  useEffect(() => {
    const iframe = document.getElementsByTagName("iframe");

    // if (ref.current) {
    //   ref.current.appendChild(iframe[0]);
    // }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Slider
        heroClass={"hero-style-1"}
        heroImg1={hero1}
        heroImg2={hero2}
        heroImg3={hero3}
      />
      <div style={{ marginTop: 20, marginBottom: 20 }} ref={ref}></div>
      <Services />
      <Images />
      <Avis />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
