import React from "react";

import img1 from "../../../../../../../images/jeux-nautiques/1.png";
import img2 from "../../../../../../../images/jeux-nautiques/2.png";
import img3 from "../../../../../../../images/jeux-nautiques/3.png";
import img4 from "../../../../../../../images/jeux-nautiques/4.png";
import img5 from "../../../../../../../images/jeux-nautiques/5.png";
import img6 from "../../../../../../../images/jeux-nautiques/6.png";
import img7 from "../../../../../../../images/jeux-nautiques/7.png";
import img8 from "../../../../../../../images/jeux-nautiques/8.png";
import img9 from "../../../../../../../images/jeux-nautiques/9.png";

import "./style.css";

const Images = (props) => {
  return (
    <div className="destination-area destination-area-small">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="destination-wrap">
              <div className="row">
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img1} alt="" />
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-right">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img3} alt="" />
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img7} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img5} alt="" />
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 custom-grid">
                  <div className="destination-right">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img6} alt="" />
                      </div>
                    </div>
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img8} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 custom-grid">
                  <div className="destination-left">
                    <div className="destination-item">
                      <div className="destination-img">
                        <img src={img9} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
