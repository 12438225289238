import React, { Fragment } from "react";

import Navbar from "../_common/Navbar";
import CardPresentation from "../_common/CardPresentation";
import Footer from "../_common/Footer";
import Scrollbar from "../_common/Scrollbar";

import Title from "./components/Title";

import dsn1 from "../../../images/destination/img-7.jpg";
import imageHeader from "../../../images/header/location-bateaux.png";

import "../../../components/shopsingle/style.css";

const LocationBateaux = () => {
  return (
    <Fragment>
      <Navbar />
      <Title
        img={imageHeader}
        pageTitle={"Boat Rental"}
        pagesub={"Boat Rental"}
      />
      <CardPresentation
        img={dsn1}
        title="Rent by the day"
        sub="With crew"
        text={
          <ul>
            <li>Luxury range semi-rigid boat</li>
            <li>Brig Eagle 10 « No Risk No Fun”</li>
            <li>11 meters</li>
            <li>12 people</li>
            <li>Charter with captain</li>
            <li>By the hour, day or half day</li>
            <li>Shuttle, Sentry box, deposit on the islands</li>
            <li>Monaco, Saint-Tropez, etc.</li>
            <li>Rates on request at 0772502045 or 0608065973</li>
          </ul>
        }
      />
      {/* <TubnailTarif
        title="Location de bateau"
        sub="Location de bateaux à la journée"
        slides={[
          {
            DImg: dsn1,
            Title: "Lorem ipsum",
            Date: "Lorem ipsum",
          },
        ]}
      /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default LocationBateaux;
