import React from "react";

import Description from "./components/description";
import Sidebar from "./components/ServiceSingle/singleComponet/sidebar";

import "./style.css";

const Details = (props) => {
  const { title, text1, text2 } = props;

  return (
    <section className="room-single-page" style={{ background: "transparent" }}>
      {/* <SearchSection /> */}
      <div className="container">
        <div className="row">
          <div className="col col-lg-8 col-12 order-lg-2">
            <div className="service-single-content">
              <Description title={title} text1={text1} text2={text2} />
            </div>
            <hr />
          </div>
          <Sidebar />
        </div>
      </div>
    </section>
  );
};

export default Details;
