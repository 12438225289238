import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import DocumentMeta from "react-document-meta";

import HomePageFr from "../fr/HomePage";
import LocationBateauxPageFr from "../fr/LocationBateauxPage";
import JeuxNautiquesPageFr from "../fr/JeuxNautiquesPage";
import ErrorPageFr from "../fr/ErrorPage";
import ContactPageFr from "../fr/ContactPage";
import TarifsPageFr from "../fr/Tarifs";

import HomePageEn from "../en/HomePage";
import LocationBateauxPageEn from "../en/LocationBateauxPage";
import JeuxNautiquesPageEn from "../en/JeuxNautiquesPage";
import ErrorPageEn from "../en/ErrorPage";
import ContactPageEn from "../en/ContactPage";
import TarifsPageEn from "../en/Tarifs";

const metaHomeFr = {
  title: "Location Jet Ski - Cannes JetRide Evasion",
  description:
    "Cannes Jet Ride Evasion - Location de jet ski à Cannes, pointe du Palm-Beach. Famille, amis, travail, nos offres de location de jet ski.",
  canonical: "https://cannesjetrideevasion.fr/fr",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaTarifsFr = {
  title: "Location Jet Ski Cannes - Tarifs",
  description:
    "Cannes Jet Ride Evasion - Location de jet ski à Cannes. Des tarifs de 30 minutes à 2h ou personnalisés, profitez à fond du jet ski.",
  canonical: "https://cannesjetrideevasion.fr/fr/tarifs",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaBateauxFr = {
  title: "Location Bateaux Cannes",
  description:
    "Cannes Jet Ride Evasion - Location de bateau à Cannes, contactez notre partenaire pour louer un bateau à l'heure ou à la journée.",
  canonical: "https://cannesjetrideevasion.fr/fr/location-bateaux",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaJeuxFr = {
  title: "Jeux Nautiques Cannes",
  description:
    "Cannes Jet Ride Evasion - Jeux nautiques à Cannes, contactez notre partenaire pour des boués tractées, wakeboard, ski-nautique, parachute, etc.",
  canonical: "https://cannesjetrideevasion.fr/fr/jeux-nautiques",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaContactFr = {
  title: "Cannes JetRide Evasion - Contact",
  description:
    "Cannes Jet Ride Evasion - Retrouvez nos informations, contact, email, réseaux sociaux et emplacement de nos locations de jet ski à Cannes.",
  canonical: "https://cannesjetrideevasion.fr/fr/contact",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const meta404Fr = {
  title: "Cannes Jet Ride Evasion - Erreur",
  description:
    "Cannes Jet Ride Evasion - Oops, la page que vous désirez n'existe pas. Veuillez retourner à l'accueil pour louer un jet ski à Cannes.",
  canonical: "https://cannesjetrideevasion.fr/fr/404",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaHomeEn = {
  title: "Jet Ski Rental - Cannes JetRide Evasion",
  description:
    "Cannes Jet Ride Evasion - Jet ski rental in Cannes, tip of the Palm-Beach. Family, friends, work, our jet ski rental offers.",
  canonical: "https://cannesjetrideevasion.fr/en",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaTarifsEn = {
  title: "Cannes Jet Ski Rental - Rates",
  description:
    "Cannes Jet Ride Evasion - Jet ski rental in Cannes. Rates from 30 minutes to 2 hours or personalized, take full advantage of the jet ski.",
  canonical: "https://cannesjetrideevasion.fr/en/prices",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaBateauxEn = {
  title: "Cannes Jet Ride Evasion - Boat rental",
  description:
    "Cannes Jet Ride Evasion - Boat rental in Cannes, contact our partner to rent a boat by the hour or by the day.",
  canonical: "https://cannesjetrideevasion.fr/en/activities/boat-rental",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaJeuxEn = {
  title: "Rods Jet Ride Evasion - Water games",
  description:
    "Cannes Jet Ride Evasion - Water games in Cannes, contact our partner for towed buoys, wakeboarding, water skiing, parachuting, etc.",
  canonical: "https://cannesjetrideevasion.fr/en/activities/water-games",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const metaContactEn = {
  title: "Jet Ski Rental Cannes - Contact",
  description:
    "Cannes Jet Ride Evasion - Find our information, contact, email, social networks and location of our jet ski rentals in Cannes.",
  canonical: "https://cannesjetrideevasion.fr/en/contact",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const meta404En = {
  title: "Cannes Jet Ride Evasion - Error",
  description:
    "Cannes Jet Ride Evasion - Oops, the page you are looking for does not exist. Please return to reception to rent a jet ski in Cannes.",
  canonical: "https://cannesjetrideevasion.fr/en/404",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rent,jet ski,cannes,alpes-maritimes,location,bateau,boat",
    },
  },
};

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/fr" />
          </Route>
          <Route
            exact
            path="/fr"
            component={() => (
              <>
                <DocumentMeta {...metaHomeFr} />;
                <HomePageFr />
              </>
            )}
          />
          <Route
            path="/fr/tarifs"
            component={() => (
              <>
                <DocumentMeta {...metaTarifsFr} />;
                <TarifsPageFr />
              </>
            )}
          />
          <Route
            path="/fr/activites/location-bateaux"
            component={() => (
              <>
                <DocumentMeta {...metaBateauxFr} />;
                <LocationBateauxPageFr />
              </>
            )}
          />
          <Route
            path="/fr/activites/jeux-nautiques"
            component={() => (
              <>
                <DocumentMeta {...metaJeuxFr} />;
                <JeuxNautiquesPageFr />
              </>
            )}
          />
          <Route
            path="/fr/contact"
            component={() => (
              <>
                <DocumentMeta {...metaContactFr} />;
                <ContactPageFr />
              </>
            )}
          />
          <Route
            path="/fr/404"
            component={() => (
              <>
                <DocumentMeta {...meta404Fr} />;
                <ErrorPageFr />
              </>
            )}
          />
          <Route
            exact
            path="/en"
            component={() => (
              <>
                <DocumentMeta {...metaHomeEn} />;
                <HomePageEn />
              </>
            )}
          />
          <Route
            path="/en/prices"
            component={() => (
              <>
                <DocumentMeta {...metaTarifsEn} />;
                <TarifsPageEn />
              </>
            )}
          />
          <Route
            path="/en/activities/boat-rental"
            component={() => (
              <>
                <DocumentMeta {...metaBateauxEn} />;
                <LocationBateauxPageEn />
              </>
            )}
          />
          <Route
            path="/en/activities/water-games"
            component={() => (
              <>
                <DocumentMeta {...metaJeuxEn} />;
                <JeuxNautiquesPageEn />
              </>
            )}
          />
          <Route
            path="/en/contact"
            component={() => (
              <>
                <DocumentMeta {...metaContactEn} />;
                <ContactPageEn />
              </>
            )}
          />
          <Route
            path="/en/404"
            component={() => (
              <>
                <DocumentMeta {...meta404En} />;
                <ErrorPageEn />
              </>
            )}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
