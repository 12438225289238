import React from "react";

import "./style.css";

const Contactpage = () => {
  return (
    <div id="Contact" className="contact-area section-padding">
      <div className="container">
        <div className="wpo-contact-info">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fa fa-facebook"></i>
                  </div>
                  <div className="info-text">
                    <span>Facebook</span>
                  </div>
                </div>
                <h2>Cannes Jet Ride Evasion : location jet ski</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fa fa-instagram"></i>
                  </div>
                  <div className="info-text">
                    <span>Instagram</span>
                  </div>
                </div>
                <h2>cannesjetrideevasion</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-email"></i>
                  </div>
                  <div className="info-text">
                    <span>E-Mail</span>
                  </div>
                </div>
                <h2>cannesjetrideevasion@gmail.com</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-null-1"></i>
                  </div>
                  <div className="info-text">
                    <span>Téléphone</span>
                  </div>
                </div>
                <h2>+33.7.72.50.20.45</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-content">
          <p>
            Pour une question ou un problème, veuillez nous contacter par
            téléphone. Nos responsables des réseaux sociaux ne sont pas
            habilités à répondre à vos demandes. »
          </p>
          <p>
            « Si vous faites partie d&#39;un Comité d&#39;entreprise ou si vous
            souhaitez organiser un événement tel qu&#39;un séminaire,
            n&#39;hésitez pas à nous contacter par téléphone ou par email ! »
          </p>
        </div>
        <div className="contact-map">
          <iframe
            title="google-map"
            src="https://maps.google.com/maps?q=port%20mour%C3%A9%20rouge%20cannes&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;
