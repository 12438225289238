import React from "react";

import about from "../../../../../../../images/blog/profile.png";

import "./style.css";

const Text = (props) => {
  return (
    <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
      <div className="wpo-blog-sidebar">
        <div className="widget profile-widget">
          <div className="profile-img">
            <img src={about} alt="" />
            <h2>
              Le Warf Sports
              <br />
              Cannes La Croisette
            </h2>
            <p>
              Ponton des plages La Môme et Mademoiselle Gray.
              <br />
              Boulevard de la Croisette, 06400 Cannes
              <br />
              <br />
              Renseignements et réservation par téléphone ou sur place.
              <br />
              <br />
              Tel: 0623969015 ou 0625683167
              <br />
              Instagram: lewarfsports
              <br />
              <a
                href="https://www.lewarfsports.com/"
                title="Location Jet Ski Cannes - Jeux nautiques"
              >
                https://www.lewarfsports.com/
              </a>
            </p>
          </div>
        </div>
        <div className="widget category-widget">
          <h3>Nos activités</h3>
          <ul>
            <li>Parachute</li>
            <li>Bouées tractées</li>
            <li>Ski-Nautique / Wakeboard</li>
            <li>Paddle</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Text;
