import React from "react";
import {
  FaMedal,
  FaSwimmingPool,
  FaStar,
  FaUmbrellaBeach,
  FaRegClock,
  FaShieldAlt,
} from "react-icons/fa";

import "./style.css";

const Services = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const serviceWrap = [
    {
      sIcon: <FaMedal />,
      title: "Our team",
      des: "A state-certified and French champion jet ski instructor to coach and supervise you.",
    },
    {
      sIcon: <FaSwimmingPool />,
      title: "The chill spirit",
      des: "A floating platform with terrace to enjoy the sun and a shuttle boat to the sound of summer.",
    },
    {
      sIcon: <FaStar />,
      title: "Our machines",
      des: "New and efficient jet skis for your rentals (SEADOO 2021 and 2022/ GTX 170/ GTX Pro 130).",
    },
    {
      sIcon: <FaUmbrellaBeach />,
      title: "The framework",
      des: "An idyllic and privileged location for your jet ski rentals along the Lérins Islands.",
    },
    {
      sIcon: <FaShieldAlt />,
      title: "Security",
      des: "An integrated anti-collision system to guarantee you the safest possible jet ski rides.",
      link: "/service-single",
    },
    {
      sIcon: <FaRegClock />,
      title: "Schedules",
      des: "Everything for our customers! We are open 7 days a week from 9 a.m. to 8 p.m. for your jet ski rentals.",
    },
  ];

  return (
    <div className="service-area section-padding">
      <div className="container">
        <div className="col-12">
          <div className="wpo-section-title">
            <h2>What makes our charm</h2>
            <span>...and our originality !</span>
          </div>
        </div>
        <div className="row">
          {serviceWrap.map((service, sev) => (
            <div className="col col-lg-4 col-md-6 custom-grid col-12" key={sev}>
              <div className="service-item">
                <div className="service-icon">{service.sIcon}</div>
                <div className="service-text">
                  <h2>{service.title}</h2>
                  <p>{service.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
