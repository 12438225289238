import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ts3 from "../../../../../images/testimonial/img-3.jpg";

import "./style.css";

class Avis extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      speed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const testimonial = [
      {
        Des: "“J'y suis allé avec mon fils pour une balade solo d'une demi heure. Équipe incroyable. Je recommande cette adresse sans hésiter !”",
        Title: "Djé D.",
        Sub: "Août 2021",
      },
      {
        Des: "“Equipe très sympathique et jet ski performants. Je recommande vivement ! Les scooters sont récents et fonctionnent parfaitement.”",
        Title: "Paul B.",
        Sub: "Canada",
      },
      {
        tsImg: ts3,
        Des: "“Nous avons fait une balade en jet ski avec des amis, super souvenir. Les gérants sont adorables et la balade en jet ski superbe”",
        Title: "Estelle K.",
        Sub: "Singapore",
      },
    ];
    return (
      <div
        className="testimonial-area section-padding"
        style={{ background: "#333" }}
      >
        <div className="container">
          <div className="col-12">
            <div className="wpo-section-title">
              <h2>Nos riders ont donné leur avis !</h2>
              <span>Il ne manque plus que le vôtre !</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial-slider clearfix">
                <Slider {...settings}>
                  {testimonial.map((tesmnl, tsm) => (
                    <div className="grid" key={tsm}>
                      <div className="ratting">
                        <ul>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                          <li>
                            <i className="fi flaticon-star"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="quote">
                        <p>{tesmnl.Des}</p>
                      </div>
                      <div className="client-info">
                        <div className="client-text">
                          <h5>{tesmnl.Title}</h5>
                          <p>{tesmnl.Sub}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Avis;
