import React from "react";

import "./style.css";

const Contactpage = () => {
  return (
    <div id="Contact" className="contact-area section-padding">
      <div className="container">
        <div className="wpo-contact-info">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fa fa-facebook"></i>
                  </div>
                  <div className="info-text">
                    <span>Facebook</span>
                  </div>
                </div>
                <h2>Cannes Jet Ride Evasion: jet ski rental</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fa fa-instagram"></i>
                  </div>
                  <div className="info-text">
                    <span>Instagram</span>
                  </div>
                </div>
                <h2>cannesjetrideevasion</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-email"></i>
                  </div>
                  <div className="info-text">
                    <span>E-Mail</span>
                  </div>
                </div>
                <h2>cannesjetrideevasion@gmail.com</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-null-1"></i>
                  </div>
                  <div className="info-text">
                    <span>Phone</span>
                  </div>
                </div>
                <h2>+33.7.72.50.20.45</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-content">
          <p>
            For a question or a problem, please contact us by phone. Our social
            media managers are not authorized to respond to your requests. »
          </p>
          <p>
            “If you are part of a Works Council or if you wish to organize an
            event such as a seminar, do not hesitate to contact us by phone or
            email! »
          </p>
        </div>
        <div className="contact-map">
          <iframe
            title="google-map"
            src="https://maps.google.com/maps?q=port%20mour%C3%A9%20rouge%20cannes&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;
